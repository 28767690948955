// DEPRECATED! Please, do not use this component going forward. This should be replaced with the same component inside /dynamicForms folder.
<template>
  <ValidationProvider :rules="field.rules" :name="field.veeAs ? field.veeAs : field.name" :vid="fieldName" :key="fieldName" #default="{ errors }" tag="div" class="field">
    <template v-if="field.field === 'input'">
      <div class="field" v-if="field.type === 'radio'">
        <label class="custom-label" v-if="field.label">
          <slot :name="`${field.name}:label`" :field="field">{{ field.label }}</slot>
          <PopoverForm :text="field.popover" v-if="field.popover" />
          <div class="help-wrapper" v-if="field.tooltip">
            <div class="help material-icons">
              help
              <span class="tooltip" v-if="Array.isArray(field.tooltip)">
                <div v-for="item in field.tooltip" :key="item.heading">
                  <h1>{{ item.heading }}</h1>
                  <p>{{ item.text }}</p>
                </div>
              </span>
              <span class="tooltip" v-else>{{ field.tooltip }}</span>
            </div>
          </div>
        </label>
        <div class="ui m-b-1 row" :class="{ [totalColumns]: true, column: true, grid: true }">
          <div v-for="column in getColumnsCount(field)" :key="column" :class="getColumnsCountClass(field)">
            <Radio
              v-for="option in getColumnOptions(field.options, column, field)"
              v-model="field.selected"
              :name="fieldName"
              :disabled="field.disabled || disabled"
              :readonly="field.readonly || readonly"
              :model-value="option.value"
              :label="option.label"
              :key="option.value"
              :class="{
                invalid: !!errors.length,
                'field-inline': field.isInline
              }"
            />
          </div>
        </div>
      </div>
      <div class="field" v-else-if="field.type === 'checkbox'">
        <template v-if="field.choice === 'multiple'">
          <label v-if="field.label">
            <slot :name="`${field.name}:label`" :field="field">{{ field.label }}</slot>
            <PopoverForm :text="field.popover" v-if="field.popover" />
          </label>
          <CheckboxesRow v-model="field.selected" :name="fieldName" :disabled="field.disabled || disabled" :readonly="field.readonly || readonly" :field="field" />
        </template>
        <template v-else>
          <Checkbox v-model="field.selected" :name="fieldName" :disabled="field.disabled || disabled" :readonly="field.readonly || readonly" :label="field.label" />
        </template>
      </div>
      <div v-else-if="field.type === 'range'">
        <label v-if="field.label">
          <slot :name="`${field.name}:label`" :field="field">{{ field.label }}</slot>
          <PopoverForm :text="field.popover" v-if="field.popover" />
        </label>
        <input type="range" v-model="field.selected" :name="fieldName" :disabled="field.disabled || disabled" :readonly="field.readonly || readonly" />
      </div>
      <div v-else-if="field.type === 'file'">
        <File
          v-model="field.selected"
          :name="fieldName"
          :disabled="field.disabled || disabled"
          :readonly="field.readonly || readonly"
          :multiple="field.choice === 'multiple'"
          :label="field.label"
          :submit-promise="submitPromise"
        />
      </div>
      <div class="field" v-else>
        <label v-if="field.label">
          <slot :name="`${field.name}:label`" :field="field">{{ field.label }}</slot>
          <PopoverForm :text="field.popover" v-if="field.popover" />
        </label>
        <div
          class="ui"
          :class="{
            left: field.icon && field.icon.position === 'left',
            right: field.icon && field.icon.position === 'right',
            icon: field.icon,
            input: true
          }"
        >
          <input
            v-model.trim="field.selected"
            :name="fieldName"
            :disabled="field.disabled || disabled"
            :readonly="field.readonly || readonly"
            :type="field.type"
            :placeholder="field.placeholder"
            :multiple="field.choice === 'multiple'"
          />
          <i class="icon material-icons ui icon-text" v-if="field.icon && field.icon.name">{{ field.icon.name }}</i>
          <i
            class="ui icon-text"
            :class="{
              icon: field.icon,
              left: field.icon && field.icon.position === 'left',
              right: field.icon && field.icon.position === 'right'
            }"
            v-if="field.icon && field.icon.text"
            >{{ field.icon.text }}</i
          >
        </div>
      </div>
    </template>

    <template v-else-if="field.field === 'textarea'">
      <div class="field">
        <label v-if="field.label">
          <slot :name="`${field.name}:label`" :field="field">{{ field.label }}</slot>
          <PopoverForm :text="field.popover" v-if="field.popover" />
        </label>
        <textarea
          v-model.trim="field.selected"
          :name="fieldName"
          :disabled="field.disabled || disabled"
          :readonly="field.readonly || readonly"
          :type="field.type"
          :placeholder="field.placeholder"
          :multiple="field.choice === 'multiple'"
        ></textarea>
      </div>
    </template>

    <template v-else-if="field.field === 'select'">
      <div class="field">
        <label v-if="field.label">
          <slot :name="`${field.name}:label`" :field="field">{{ field.label }}</slot>
          <PopoverForm :text="field.popover" v-if="field.popover" />
          <div class="help-wrapper" v-if="field.tooltip">
            <div class="help material-icons">
              help
              <span class="tooltip" v-if="Array.isArray(field.tooltip)">
                <div v-for="item in field.tooltip" :key="item.heading">
                  <h1>{{ item.heading }}</h1>
                  <p>{{ item.text }}</p>
                </div>
              </span>
              <span class="tooltip" v-else>{{ field.tooltip }}</span>
            </div>
          </div>
        </label>
        <select
          v-model="field.selected"
          :name="fieldName"
          :disabled="field.disabled || disabled"
          :readonly="field.readonly || readonly"
          :multiple="field.choice === 'multiple'"
          :class="{ empty: field.selected === '', 'm-t-10': !field.label }"
          class="ui selection dropdown"
        >
          <option value hidden disabled selected>{{ field.placeholder }}</option>
          <option v-for="option in field.options" :value="option.value" :key="option.value">{{ option.label }}</option>
        </select>
      </div>
    </template>

    <template v-else-if="field.field === 'survey-documents'">
      <SurveyDocuments
        v-model="field.selected"
        :rules="field.rules"
        :formName="value.name"
        :name="fieldName"
        :multiple="field.choice === 'multiple'"
        :label="field.label"
        :submit-promise="submitPromise"
      />
    </template>

    <template v-else-if="field.field === 'datepicker'">
      <label v-if="field.label">
        <slot :name="`${field.name}:label`" :field="field">{{ field.label }}</slot>
        <PopoverForm :text="field.popover" v-if="field.popover" />
      </label>
      <div class="input-group">
        <datepicker
          :class="{ 'orientation-top': field.isOrientationTop }"
          v-model="field.selected"
          :name="fieldName"
          :id="fieldName"
          format="dd/MM/yyyy"
          input-class="datepicker-input"
          :use-utc="true"
          :open-date="field.openDate || new Date(String(new Date().getFullYear() - 18))"
          :disabledDates="field.disabledDates"
          :monday-first="true"
          :disabled="field.disabled || disabled"
          :placeholder="field.placeholder"
        ></datepicker>
        <label class="ui date-icon-group" v-if="field.icon">
          <i class="icon material-icons" v-if="field.icon && field.icon.name">{{ field.icon.name }}</i>
          <i
            class="ui icon-text"
            :class="{
              icon: field.icon,
              left: field.icon && field.icon.position === 'left',
              right: field.icon && field.icon.position === 'right'
            }"
            v-if="field.icon && field.icon.text"
            >{{ field.icon.text }}</i
          >
        </label>
      </div>
    </template>

    <template v-else-if="field.field === 'datepicker-dropdown'">
      <label v-if="field.label">
        <slot :name="`${field.name}:label`">{{ field.label }}</slot>
        <PopoverForm :text="field.popover" v-if="field.popover" />
      </label>
      <date-dropdown min="1901" :max="maxYear" v-model="field.selected" :name="field.name" :field="field" :default="field.selected" :rules="field.rules" />
    </template>

    <template v-else-if="field.field === 'dummy'"></template>

    <FieldError class="ui pointing above red basic label" v-bind="{ errors }" v-if="field.field !== 'survey-documents'" />
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import Datepicker from 'vuejs-datepicker'
import Radio from './Radio'
import Checkbox from './Checkbox'
import CheckboxesRow from './CheckboxesRow'
import File from './File'
import SurveyDocuments from './SurveyDocuments'
import PopoverForm from './PopoverForm'
import FieldError from '@/components/forms/FieldError'
import { getColumnsCount, getColumnsCountClass, getColumnOptions, getColumnsCountInWords } from '@/utils/formHelpers'

export default {
  name: 'FieldBuilder',
  components: {
    ValidationProvider,
    Datepicker,
    Radio,
    Checkbox,
    CheckboxesRow,
    File,
    SurveyDocuments,
    PopoverForm,
    FieldError
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      type: Object,
      required: true
    },
    submitPromise: {
      type: Promise
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      maxYear: new Date().getFullYear().toString()
    }
  },
  computed: {
    fieldName() {
      return this.field.veeName ? this.field.veeName : this.field.name
    },
    totalColumns() {
      return getColumnsCountInWords(this.field)
    }
  },
  methods: {
    getColumnsCount,
    getColumnsCountClass,
    getColumnOptions
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/variables';

.ui {
  &.date-icon-group {
    position: absolute;
    right: 0;
    transform: translate(-1rem, -2rem);
  }
  &.form .fields > .field {
    width: 100%;
  }
  &.form .field {
    .v-popover {
      display: inline-block;
      position: absolute;
    }
    .custom-label {
      margin-bottom: 1rem;
    }
    label {
      display: flex;

      .help-wrapper {
        padding-left: 8px;
        margin-top: -2px;

        @media only screen and (max-width: $breakpoint-sm-max) {
          padding-top: 0;
        }

        div.help {
          color: var(--color-neutral-100);
          width: 26px;
          height: 26px;
          border-radius: 50%;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          transition: all 0.5s;

          @media only screen and (max-width: $breakpoint-sm-max) {
            position: absolute;
            right: 0;
            top: 12px;
          }

          &:hover {
            color: var(--color-neutral-600);
            transform: translateX(4px);
            z-index: 2;
          }

          &.inactive {
            background-color: transparent;
            cursor: inherit;
          }

          span.tooltip {
            left: 42px;
            width: 300px;
            background-color: $color-white;
            border: 1px solid var(--color-neutral-50);
            color: $color-black;
            text-align: center;
            font-size: 14px;
            padding: 5px;
            border-radius: 6px;
            position: absolute;
            font-family: 'Public Sans', sans-serif;
            white-space: initial;
            z-index: 1;
            transition: all 0.3s;
            transform: scale(0);
            box-shadow: $lift-box-shadow;

            h1 {
              font-size: 16px;
              line-height: 16px;
              margin: 4px 0;
            }

            p {
              font-size: 12px;
              margin-bottom: 8px;
            }

            @media only screen and (max-width: $breakpoint-sm-max) {
              left: -250px;
            }

            &::after {
              content: ' ';
              position: absolute;
              z-index: 2;
              top: 50%;
              right: 100%;
              margin-top: -5px;
              border-width: 5px;
              border-style: solid;
              border-color: transparent var(--color-neutral-50) transparent transparent;

              @media only screen and (max-width: $breakpoint-sm-max) {
                top: 50%;
                left: 100%;
                border-color: transparent transparent transparent var(--color-neutral-50);
              }
            }
          }

          &:hover .tooltip {
            transform: scale(1);
          }
        }
      }
    }
  }
}
.input-group {
  position: relative;
}
</style>
