<template>
  <ValidationObserver #default="{ validate, reset }" slim>
    <form class="ui form" :name="value.name" @submit.prevent="submitHandler(validate, reset)" enctype="multipart/form-data" v-promise-btn="{ action: 'submit' }" novalidate>
      <slot name="defaultTop" />
      <slot v-for="(group, groupIndex) in value.groups" :name="group.name ? group.name : `group-${groupIndex}`" v-bind="{ group, value, validate, reset }">
        <div :key="groupIndex" class="sub-section">
          <div class="-header" v-if="group.title">
            <label class="ui text dark header f400">
              <slot :name="`group:${group.name ? group.name : groupIndex}:title`" :group="group">{{ group.title }}</slot>
            </label>
          </div>
          <div class="-content">
            <div
              v-for="(fieldSet, fieldsetIndex) in visibleFields(group, value.groups)"
              :class="{
                fields: fieldSet.length > 0,
                [getColumnsCountInWords({ columns: fieldSet.length })]: fieldSet.length > 1,
                'ui m-b-1': fieldSet.length === 1
              }"
              :key="fieldsetIndex"
            >
              <slot v-for="field in fieldSet" :name="field.name" v-bind="{ field, fieldSet, group, value, validate, reset }">
                <FieldBuilder
                  :field="field"
                  :value="value"
                  :key="field.name"
                  :submitPromise="submitPromise"
                  :disabled="disabled"
                  :readonly="readonly"
                  v-if="!field.dependsOn || isFieldVisible(field, group, value.groups)"
                >
                  <template v-for="(slot, name) in $scopedSlots" #[name]="data">
                    <slot :name="name" v-bind="data" />
                  </template>
                </FieldBuilder>
              </slot>
            </div>
          </div>
        </div>
      </slot>
      <slot />
      <div class="ui action-container m-t-1">
        <slot name="beforeSubmit" />
        <button type="submit" class="ui button btn-primary business-gradient circular form-submit-btn relative">
          <span class="inline-middle">{{ submitText || 'save' }}</span>
          <i class="icon material-icons" v-if="iconName">{{ iconName }}</i>
        </button>
        <slot name="afterSubmit" />
        <div class="text-danger" v-if="errorResponseMessage">{{ errorResponseMessage }}</div>
        <slot name="modals" v-bind="{ submit: submitHandler, validate, reset }" />
      </div>
    </form>
  </ValidationObserver>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import FieldBuilder from './FieldBuilder'
import { isFieldVisible, prepareFormData, getColumnsCount, getColumnsCountClass, getColumnOptions, getColumnsCountInWords } from '@/utils/formHelpers'

export default {
  name: 'DynamicForm',
  components: {
    ValidationObserver,
    FieldBuilder
  },
  props: {
    value: {
      type: Object,
      required: true
    },
    submitPromise: {
      type: Promise
    },
    submitText: {
      type: String
    },
    iconName: {
      type: String
    },
    iconScale: {
      type: Number
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isPartOfJourney: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      errorResponse: null
    }
  },
  computed: {
    isTabs() {
      return this.value.groupsType === 'tabs'
    },
    errorResponseMessage() {
      if (!this.errorResponse || !this.errorResponse.data) return ''
      if (this.errorResponse.data.modelState) {
        const modelStateList = Object.values(this.errorResponse.data.modelState)
        if (modelStateList.length) return modelStateList[0][0]
      }
      if (this.errorResponse.data.message) return this.errorResponse.data.message
      return ''
    }
  },
  methods: {
    getColumnsCount,
    getColumnsCountClass,
    getColumnOptions,
    getColumnsCountInWords,
    submitHandler(validate, reset) {
      this.errorResponse = null
      return validate().then(result => {
        if (result) {
          const formData = prepareFormData(this.value)
          this.$emit('df-submit', formData)
          return new Promise((resolve, reject) =>
            this.$nextTick(() => {
              if (this.submitPromise && this.submitPromise.then) {
                this.submitPromise.then(resolve).catch(e => {
                  this.errorResponse = e.response
                  resolve()
                })
              } else {
                resolve()
              }
            })
          ).then(() => reset())
        } else {
          const errorElement = this.$el.querySelector('[aria-invalid="true"]') || this.$el.querySelector('.text-danger')
          errorElement.parentNode.parentNode.scrollIntoView({ block: 'start', behavior: 'smooth' })
        }
      })
    },
    visibleFields(group, groups) {
      return group.fields.filter(fieldSet => {
        const visibleFields = fieldSet.filter(field => !field.dependsOn || isFieldVisible(field, group, groups))
        return visibleFields.length > 0
      })
    },
    isFieldVisible
  },
  watch: {
    submitPromise() {
      this.errorResponse = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/styles/swoop/variables';

.ui.form {
  .form-group {
    all: unset;
    display: block;
    border: 0;
    padding: 0;
    margin: 0;
  }
  .action-container {
    .form-submit-btn {
      margin-top: 1rem;
      min-width: 196px;
      .icon {
        margin-left: 1rem;
        transform: translateY(2px);
      }
    }
  }
  /deep/ {
    .sub-section {
      .-header {
        padding-bottom: 1rem;
        border-bottom: 1px solid $default-border-color;
      }
      .-content {
        margin-top: 1.5rem;
      }
    }
    input[type='text'],
    input[type='number'],
    input[type='password'],
    input[type='email'],
    input[type='tel'],
    input[type='url'],
    select,
    textarea {
      width: 100%;
    }
    textarea {
      max-width: 100%;
      min-height: 64px;
    }
    .checkbox,
    .radio {
      width: 100%;
      margin-bottom: 10px;
      @media only screen and (max-width: 770px) {
        margin-left: 10px;
      }
      &:last-child {
        margin-bottom: 0;
        @media only screen and (max-width: 770px) {
          margin-bottom: 10px;
        }
      }
      &.field-inline {
        width: auto;
        margin-right: 15px;
        margin-bottom: 0;
      }
    }
    .checkbox {
      margin-bottom: 10px;
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-position: 97% 50%;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='10px' height='18px' viewBox='0 0 10 18' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3C!-- Generator: sketchtool 64 (101010) - https://sketch.com --%3E%3Ctitle%3E3386B3CA-B14D-4088-A519-6CEF13974B68@1x%3C/title%3E%3Cdesc%3ECreated with sketchtool.%3C/desc%3E%3Cg id='Full-Flow' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='1.12---FMP---step-7' transform='translate(-503.000000, -447.000000)'%3E%3Cg id='form/with-label/select/business-type' transform='translate(150.000000, 411.000000)'%3E%3Cg id='ic_unfold_more' transform='translate(346.000000, 33.000000)'%3E%3Cg id='Icon-24px'%3E%3Cpolygon id='Shape' points='0 0 24 0 24 24 0 24'%3E%3C/polygon%3E%3Cpath d='M12,5.83 L15.17,9 L16.58,7.59 L12,3 L7.41,7.59 L8.83,9 L12,5.83 L12,5.83 Z M12,18.17 L8.83,15 L7.42,16.41 L12,21 L16.59,16.41 L15.17,15 L12,18.17 L12,18.17 Z' id='Shape' fill='%23546A7B'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }
}

@media only screen and (max-width: 770px) {
  [class*='mobile hidden'],
  [class*='tablet only']:not(.mobile),
  [class*='computer only']:not(.mobile),
  [class*='large screen only']:not(.mobile),
  [class*='widescreen only']:not(.mobile),
  [class*='or lower hidden'],
  .ui.button {
    width: 100%;
    margin-bottom: 1rem;
  }
}
</style>
