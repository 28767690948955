<template>
  <div class="ui checkboxesrow row" :class="{ [totalColumns]: true, column: true, grid: true }">
    <div class="row">
      <div class="column col-xs-12" v-for="column in getColumnsCount(field)" :key="column" :class="getColumnsCountClass(field)">
        <Checkbox
          v-for="option in getColumnOptions(localOptions, column, field)"
          v-model="option.checked"
          :name="field.name"
          :label="option.label"
          :key="option.value"
          :class="{ 'field-inline': field.isInline }"
          @input="checkboxInputHandler"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from './Checkbox'
import { getColumnsCount, getColumnsCountClass, getColumnOptions, getColumnsCountInWords } from '@/utils/formHelpers'

export default {
  name: 'CheckboxesRow',
  props: ['value', 'field', 'group'],
  components: {
    Checkbox
  },
  computed: {
    localOptions() {
      if (!this.field || !this.field.options) return []
      return this.field.options.map(option => {
        const checked = this.value ? this.value.includes(option.value) : false
        return { ...option, checked }
      })
    },
    totalColumns() {
      return getColumnsCountInWords(this.field)
    }
  },
  methods: {
    getColumnsCount,
    getColumnsCountClass,
    getColumnOptions,
    checkboxInputHandler() {
      const selected = this.localOptions.filter(o => o.checked).map(o => o.value)
      this.$emit('input', selected)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/styles/swoop/_variables.scss';
.ui.checkboxesrow {
  .row {
    margin-left: 0.1rem;
  }
}
</style>
