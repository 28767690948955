<template>
  <popover placement="top" trigger="hover click focus" @click.native.prevent>
    <i class="icon-info material-icons">help</i>
    <template #popover>{{text}}</template>
  </popover>
</template>

<script>
export default {
  name: 'PopoverForm',
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.icon-info {
  font-size: 22px;
  cursor: pointer;
}
</style>
