// DEPRECATED! Please, do not use this component going forward. This should be replaced with the same component inside /dynamicForms folder.
<template>
  <label>
    <div class="form-label">{{ label }}</div>
    <input ref="fileEl" type="file" :name="name" :multiple="multiple" @change="filesChangeHandler" />
  </label>
</template>

<script>
export default {
  name: 'InputFile',
  props: {
    value: {
      required: true
    },
    name: {
      type: String,
      required: true
    },
    multiple: {
      type: Boolean,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    submitPromise: {
      required: false
    }
  },
  methods: {
    filesChangeHandler(e) {
      this.$emit('input', Array.from(e.target.files))
    },
    renew() {
      this.$refs.fileEl.value = ''
      this.$emit('input', '')
    }
  },
  watch: {
    submitPromise(newSubmitPromise) {
      newSubmitPromise.then(this.renew)
    }
  }
}
</script>
